<template>
  <ag-grid-vue
    style="width: 100%; height: 500px"
    :columnDefs="columnDefs"
    :rowData="rowData"
    class="ag-theme-alpine"
    domLayout="autoHeight"
    :overlayNoRowsTemplate="overlayNoRowsTemplate"
    @firstDataRendered="onFirstDataRendered"
    @rowClicked="onRowClicked"
  ></ag-grid-vue>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';

export default {
  components: {
    'ag-grid-vue': AgGridVue,
  },
  data() {
    return {
      columnDefs: [
        {
          field: 'checkbox',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellRenderer() {
            return '<span class="ag-cell-checkbox"></span>';
          },
          width: 40, // Adjust the width as needed
        },
        { field: 'Checkpoints' },
        // Add more column definitions as needed
      ],
      rowData: [
        // { Node: 1, Type: 'John' },
        // { Node: 2, Type: 'Jane' },
        // Add more row data as needed
      ],
      overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No rows to show</span>',
    };
  },
  computed: {
    questData() {
      return this.$store.state.quests.quest_graph_data;
    },
  },
  watch: {
    questData(val) {
      this.rowData.push({ Data: val, Checkpoints: val.label, Type: val.tjtype || 'wikilink' });
      console.log('ongoing-quest', val.children);
      if (val.children) {
        val.children.forEach((eachChild) => {
          this.rowData.push({ Data: eachChild, Checkpoints: eachChild.label, Type: eachChild.tjType || 'wikilink' });
          if (eachChild.children) {
            eachChild.children.forEach((eachSubChild) => {
              this.rowData.push({ Data: eachSubChild, Checkpoints: eachSubChild.label, Type: eachSubChild.tjType || 'wikilink' });
            });
          }
        });
      }
    },
  },
  methods: {
    onFirstDataRendered(params) {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
    },
    onRowClicked(params) {
      this.$store.dispatch('setCurrentQuestNode', params.data.Data);
      params.api.getSelectedNodes().forEach((node) => {
        if (node.rowIndex === params.node.rowIndex) {
          node.setSelected(!node.isSelected());
        } else {
          node.setSelected(false);
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.ag-overlay-no-rows-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bold;
    font-size: 18px;
    color: gray;
  }

  /* Remove outline for elements within ag-Grid */
  .ag-cell-focus .ag-cell-focus-outline,
  .ag-cell-focus .ag-cell-inner,
  .ag-cell-focus .ag-react-container,
  .ag-cell-focus .ag-cell-value,
  .ag-cell-focus .ag-cell-checkbox {
    outline: none !important;
  }
</style>
